import React from "react";
import { graphql } from 'gatsby'
import Layout from '../layout/layout'
// import { Link } from "gatsby"
// import "./style.scss";

export default function Services(props) {
  return (
        <Layout>
      <div className="ServiceCard"> 
        <h1>Skills</h1>
        <div>
        {console.log("Services")}
        {/* {console.log(props)} */}
        {props.data.allWordpressService.edges.filter(({node}) => {
       
            return true;
    
        }).map(({ node }) => (
            <div>
                 { (node.services_card.servicesCardImage != null)
            ? <picture className='service_picture'> <img alt="" srcSet={node.services_card.servicesCardImage.localFile.childImageSharp.fluid.srcSet}/> </picture>
            :  <picture className='service_picture'> <img alt="" srcSet="https://via.placeholder.com/300x200"/> </picture> 
            }
            
            <h2>{node.services_card.title}</h2>
            {/* <p className="" >{node.services_card.secondHeadline}</p> */}
              </div>
            ))}     
          </div>
      </div>
      </Layout>
  )
}


export const pageQuery = graphql`
{
  allWordpressService (filter: {}, sort: {fields: date, order: DESC}){
    edges {
      node {
        services_card {
          servicesCardImage {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
          secondHeadline
          indexPageDisplay
          title
        }
      }
    }
  }
}
`